<template>
	<div id="map">
		<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A4feab7e13b390492a8647872a2903d53a78a63a8affe1f0ff0e6240013d1d7a4&amp;source=constructor" width="100%" height="400px" frameborder="0"></iframe>
	</div>
</template>

<style>
</style>

<script>
export default {
	name: 'Map-component'
}

</script>
